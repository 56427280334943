<template>
  <v-container class="pa-0" fluid>
    <v-app-bar app color="primary" fixed height="64">
      <v-app-bar-nav-icon v-if="$auth.check()" color="secondary" @click.stop="drawer = !drawer"/>
      <v-btn v-else :to="{name: 'login'}" dark depressed text>login / register</v-btn>
      <v-spacer/>
      <v-row v-if="$vuetify.rtl && $vuetify.breakpoint.smAndUp" :style="{cursor: 'pointer', 'max-width': 'max-content'}"
             justify="center" no-gutters @click="$router.push({name: 'main'})">
        <v-icon class="mx-1" dark size="50px">mdi-alpha-i-circle</v-icon>
        <v-icon class="mx-1" dark size="50px">mdi-alpha-f-circle</v-icon>
        <v-icon class="mx-1" dark size="50px">mdi-alpha-k-circle</v-icon>
      </v-row>
      <v-row v-if="!$vuetify.rtl && $vuetify.breakpoint.smAndUp"
             :style="{cursor: 'pointer', 'max-width': 'max-content'}"
             justify="center" no-gutters @click="$router.push({name: 'main'})">
        <v-icon class="mx-1" dark size="50px">mdi-alpha-k-circle</v-icon>
        <v-icon class="mx-1" dark size="50px">mdi-alpha-f-circle</v-icon>
        <v-icon class="mx-1" dark size="50px">mdi-alpha-i-circle</v-icon>
      </v-row>
      <v-spacer/>
      <kurcc-locale-selector/>
      <v-btn class="secondary mx-sm-2 mx-1" :small="$vuetify.breakpoint.smAndDown" dark icon>
        <v-icon :small="$vuetify.breakpoint.smAndDown">mdi-facebook</v-icon>
      </v-btn>
      <v-btn class="secondary mx-sm-2 mx-1" :small="$vuetify.breakpoint.smAndDown" dark icon>
        <v-icon :small="$vuetify.breakpoint.smAndDown">mdi-twitter</v-icon>
      </v-btn>
      <v-btn class="secondary mx-sm-2 mx-1" :small="$vuetify.breakpoint.smAndDown" dark icon>
        <v-icon :small="$vuetify.breakpoint.smAndDown">mdi-instagram</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-if="$auth.check()" v-model="drawer" :right="$vuetify.rtl" app temporary>
      <kurcc-app-bar-navigation-drawer-items @item-click="drawer = false"/>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
export default {
  name: 'KurccAppBar',
  data: () => ({
    drawer: false
  }),
  components: {
    KurccAppBarNavigationDrawerItems: () => import('@/modules/app/components/KurccAppBarNavigationDrawerItems'),
    KurccLocaleSelector: () => import('@/modules/app/components/KurccLocaleSelector')
  }
}
</script>
